import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import mediaqueries from '@styles/media'

import { SectionHeading } from '../Section'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface ShowcaseItem {
  industry: string
  title: string
  caption: string
  link: string
  cta?: string
  image?: {
    name: String
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  imageAlt: string
}

interface ShowcaseProps {
  items: ShowcaseItem[]
  colorOverride: string
}

const ShowcaseItemLink = styled(Link)`
  color: ${(p) => (p.color ? p.color : p.theme.colors.linkUnvisited)};
`

const ShowcaseContainer = styled.div<{ singleItem?: boolean }>`
  display: grid;
  column-gap: 75px;

  ${(p) =>
    p.singleItem
      ? `
    grid-template-columns: 1fr;
    text-align: center;

    div {
      text-align: center;
      margin: 0 auto;
    }
    
    p {
      width: 700px;
    }
    `
      : 'grid-template-columns: 1fr 1fr;'}

  p {
    ${mediaqueries.desktop`
      width: 100%;
    `}
  }
`

const ShowcaseItem = styled.div`
  text-align: left;

  p {
    margin-bottom: 20px;
  }
`

const Showcase: React.FC<ShowcaseProps> = ({ items, colorOverride }) => {
  const singleItem = items.length === 1

  const itemRenders = items.map((item, index) => {
    return (
      <ShowcaseItem key={index}>
        <SectionHeading.h2>{item.industry}</SectionHeading.h2>
        {item.image && (
          <GatsbyImage
            alt="Showcase item image"
            image={item.image.childImageSharp.gatsbyImageData}
          ></GatsbyImage>
        )}
        <SectionHeading.h4 colorOverride={colorOverride}>
          {item.title}
        </SectionHeading.h4>
        <p>{item.caption}</p>
        <ShowcaseItemLink to={item.link}>
          {item.cta || 'Read more...'}
        </ShowcaseItemLink>
      </ShowcaseItem>
    )
  })

  return (
    <ShowcaseContainer singleItem={singleItem}>{itemRenders}</ShowcaseContainer>
  )
}

export default Showcase
